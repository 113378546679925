<template>
  <b-container class="main-container d-flex justify-content-center mt-5 mb-5">
    <b-row class="main-row">
      <b-col>
        <h2 v-if="$route.params.id">
          Edit: {{ form.title }} Project
        </h2>
        <h2 v-else>
          Upload a project.
        </h2>
        <hr>
        <br>
        <b-form @submit.prevent="onSubmit">
          <b-form-group
            id="input-group-title"
            label="Project Title*"
            label-for="input-title"
          >
            <b-form-input
              id="input-title"
              v-model="form.title"
              name="title"
              placeholder="Javascript Developer"
              required
            />
          </b-form-group>
          <b-form-group
            v-if="['admin', 'manager'].includes($store.state.auth.user.role)"
            id="input-group-5"
            class="pb-4"
            label="Status*"
            label-for="input-5"
          >
            <b-form-select
              id="input-5"
              v-model="form.status"
              name="status"
              :options="projectStatuses"
              required
            />
            <div
              v-if="form.status === PROJECT_STATUSES.closed.value"
            >
              <b class="py-4">Closing reason</b>
              <b-form-select
                id="input-5"
                v-model="form.closed_reason"
                name="closed_reason"
                :options="closingReasons"
                required
              />
            </div>
          </b-form-group>
          <b-form-group
            v-if="isAdmin"
            id="input-group-client"
            label="Client*"
            label-for="input-client"
          >
            <FormTags
              v-model="form.business_id"
              name="business_id"
              :options="businessOptions"
              button-text="Choose Client"
              required
              :limit="1"
            />
          </b-form-group>
          <b-form-group
            v-if="isAdmin"
            id="input-group-primary-contact"
            label="Primary Contact*"
            label-for="input-primary-contact"
          >
            <FormTags
              v-model="form.primary_contacts"
              name="primary_contacts"
              :options="primaryContactOptions"
              button-text="Choose Primary Contact"
              required
            >
              <template #item="{ option }">
                <b>{{ option.full_name }}</b><hr>email:<b>{{ option.email }}</b>
              </template>
            </FormTags>
          </b-form-group>
          <b-form-group
            v-if="isAdmin"
            id="input-group-secondary-contact"
            label="Secondary Contact"
            label-for="input-secondary-contact"
          >
            <FormTags
              v-model="form.secondary_contacts"
              name="secondary_contacts"
              :options="secondaryContactOptions"
              button-text="Choose Secondary Contact"
            >
              <template #item="{ option }">
                <b>{{ option.full_name }}</b><hr>email:<b>{{ option.email }}</b>
              </template>
            </FormTags>
          </b-form-group>
          <b-form-group
            id="input-group-roles"
            label="Job Category*"
            label-for="input-roles"
          >
            <FormTags
              v-model="form.roles"
              name="roles"
              :options="roleOptions"
              button-text="Choose Job Category"
              required
            />
          </b-form-group>
          <b-form-group
            id="input-group-urgency"
            label="Urgency*"
            label-for="input-urgency"
          >
            <b-form-select
              id="input-urgency"
              v-model="form.urgency"
              name="urgency"
              :options="urgencyOptions"
              required
            />
          </b-form-group>
          <b-form-group
            id="input-group-vacancies"
            label="Number Of Vacancies*"
            label-for="input-vacancies"
          >
            <b-form-input
              id="input-vacancies"
              v-model="form.vacancies"
              name="vacancies"
              placeholder="2 roles/positions"
              required
            />
          </b-form-group>
          <b-form-group
            id="input-group-link"
            label="Job URL"
            label-for="input-link"
          >
            <b-form-input
              id="input-link"
              v-model="form.link"
              name="link"
              type="url"
              placeholder="URL/Link to company's job ad"
            />
          </b-form-group>
          <b-form-group
            id="input-group-roles"
            label="Geographical Regions"
            label-for="input-roles"
          >
            <FormTags
              v-model="form.regions"
              name="regions"
              :options="regionsOptions"
              button-text="Choose Regions"
            />
          </b-form-group>
          <b-form-group
            id="input-group-preferred_locations"
            label="Preferred Countries Or Cities"
            label-for="input-preferred_locations"
          >
            <b-form-textarea
              id="input-preferred_locations"
              v-model="form.preferred_locations"
              name="preferred_locations"
              placeholder="Preferred locations where the candidate should be"
            />
          </b-form-group>
          <b-form-group
            id="input-group-scope-work"
            label="Working Type*"
            label-for="input-scope-work"
          >
            <FormTags
              v-model="form.working_types"
              name="working_types"
              :options="workingTypeOptions"
              button-text="Choose Working Types"
              required
            />
          </b-form-group>
          <b-form-group
            id="input-group-office"
            label="Office Location"
            label-for="input-office"
          >
            <b-form-input
              id="input-office"
              v-model="form.office"
              name="office"
              placeholder="Tartu/Tallinn or remote"
            />
          </b-form-group>
          <b-form-group
            id="input-group-salary"
            label="Salary Range"
            label-for="input-salary"
          >
            <b-form-input
              id="input-salary"
              v-model="form.salary"
              name="salary"
              placeholder="2000-3000 net wage"
            />
          </b-form-group>
          <b-form-group
            id="input-group-scope-work"
            label="Scope Of Work*"
            label-for="input-scope-work"
          >
            <FormTags
              v-model="form.scopes"
              name="scopes"
              :options="scopesOptions"
              button-text="Choose Scope"
              required
            />
          </b-form-group>
          <b-form-group
            id="input-group-services"
            label="Services Needed*"
            label-for="input-services"
          >
            <FormTags
              v-model="form.services"
              name="services"
              :options="servicesOptions"
              button-text="Choose Services"
              required
            />
          </b-form-group>
          <b-form-group
            id="input-group-recruiter_location"
            label="Recruiter Location*"
            label-for="input-recruiter_location"
          >
            <b-form-select
              id="input-recruiter_location"
              v-model="form.recruiter_location"
              name="recruiter_location"
              :options="RecruiterLocationOptions"
              required
            />
          </b-form-group>
          <b-form-group
            v-if="isAdmin"
            id="input-group-recruiter-type"
            label="Recruiter Type*"
            label-for="input-recruiter-type"
          >
            <FormTags
              v-model="form.recruiter_types"
              name="recruiter_types"
              :options="recruiterTypeOptions"
              button-text="Choose Type(s)"
              required
            />
          </b-form-group>
          <b-form-group
            v-if="isAdmin"
            id="input-group-budget-range"
            label="Budget Range*"
            label-for="input-budget-range"
          >
            <FormTags
              v-model="form.fee_ranges"
              name="fee_ranges"
              :options="feeRangeOptions"
              button-text="Choose Fee Range(s)"
              required
            />
          </b-form-group>
          <b-form-group
            id="input-group-pricing-model"
            label="Pricing Model Preference*"
            label-for="input-pricing-model"
          >
            <FormTags
              v-model="form.pricing_models"
              name="pricing_models"
              :options="pricingModelsOptions"
              button-text="Choose Pricing Model(s)"
              required
            />
          </b-form-group>
          <b-form-group
            id="input-group-most_important"
            label="3 Most Important Things About The Recruiter*"
            label-for="input-most_important"
          >
            <b-form-textarea
              id="input-most_important"
              v-model="form.most_important"
              name="most_important"
              placeholder="loyal,honest,thorough"
              required
            />
          </b-form-group>
          <b-form-group
            id="input-group-description"
            label="Project Description*"
            label-for="input-description"
          >
            <b-form-textarea
              id="input-description"
              v-model="form.description"
              name="description"
              placeholder="Add project description here"
              rows="12"
              required
            />
          </b-form-group>
          <b-button
            class="mb-4"
            type="submit"
            variant="primary"
          >
            Submit
          </b-button>
          <b-modal
            id="modal-1"
            class="m-auto"
            size="lg"
            title="Hurray, project added!"
            centered
          >
            <p class="my-4">
              What would you like to do next?
            </p>
            <template #modal-footer>
              <b-button
                size="sm"
                variant="primary-light"
                @click="addMoreProjects"
              >
                Add More Projects
              </b-button>
              <b-button
                size="sm"
                variant="primary-light"
                to="/"
              >
                Go Back To Home
              </b-button>
              <b-button
                size="sm"
                variant="primary"
                @click="$router.push(`/project/${$route.params.id}`)"
              >
                Next: View Created Project
              </b-button>
            </template>
          </b-modal>
        </b-form>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import axios from '@/helpers/axios'
import FormTags from '@/components/FormTags.vue'
import { CLOSING_REASONS, PROJECT_STATUSES, statusToArray } from '@/helpers/statuses'

const GEO_REGIONS_ORDER = {
  Globally: 1,
  'North America': 2,
  'Central America': 3,
  'South America': 4,
  'The Caribbean': 5,
  Africa: 6,
  'Western Europe': 7,
  'Northern Europe': 8,
  'Eastern Europe': 9,
  'Southern Europe': 10,
  'Middle East': 11,
  'Central Asia': 12,
  'East Asia': 13,
  'South Asia': 14,
  'Southeast Asia': 15,
  Oceania: 16,
}

export default {
  name: 'AddProjectForm',
  components: {
    FormTags,
  },
  data () {
    return {
      PROJECT_STATUSES,
      form: {},
      business: [],
      businessOptions: [],
      primaryContact: [],
      primaryContactOptions: [],
      secondaryContact: [],
      secondaryContactOptions: [],
      roles: [],
      roleOptions: [],
      urgencyOptions: [
        'Starting the search',
        'We have been looking for 1-3 months',
        'We have been looking for 3+ months',
      ],
      regions: [],
      regionsOptions: [],
      workingType: [],
      workingTypeOptions: [],
      scopes: [],
      scopesOptions: [],
      services: [],
      servicesOptions: [],
      RecruiterLocationOptions: [
        { text: 'Not important', value: 'Not important' },
        { text: 'Local', value: 'Local' },
      ],
      recruiterType: [],
      recruiterTypeOptions: [],
      feeRange: [],
      feeRangeOptions: [],
      pricingModels: [],
      pricingModelsOptions: [],
      projectStatuses: statusToArray(PROJECT_STATUSES),
      closingReasons: [
        {
          text: null,
          value: null,
        },
        ...statusToArray(CLOSING_REASONS),
      ],
    }
  },
  computed: {
    isAdmin: function () {
      return ['admin', 'manager'].includes(this.$store.state.auth.user.role)
    },
  },
  mounted () {
    if (this.isAdmin) {
      axios.get('/admin/v2/businesses')
        .then(({ data }) => {
          let temp = this.mapValues(data)
          temp = temp.sort((a, b) => a.name.localeCompare(b.name))
          this.businessOptions = temp
        })
      axios.get('/admin/v2/users')
        .then(({ data }) => {
          let temp = this.mapValues(data, false, 'full_name')
          temp = temp.sort((a, b) => a.full_name.localeCompare(b.full_name))
          this.primaryContactOptions = temp
          this.secondaryContactOptions = temp
        })
    }
    axios.get('/public/v2/values/roles')
      .then(({ data }) => {
        let temp = this.mapValues(data, false, 'label')
        temp = temp.sort((a, b) => a.label.localeCompare(b.label))
        this.roleOptions = temp
      })
    axios.get('/public/v2/values/regions')
      .then(({ data }) => {
        let temp = this.mapValues(data, false, 'label')
        temp = temp.map(option => {
          option.order = GEO_REGIONS_ORDER[option.label]
          return option
        })
        temp = temp.sort((a, b) => (a.order > b.order) ? 1 : -1)
        this.regionsOptions = temp
      })
    axios.get('/public/v2/values/services')
      .then(({ data }) => {
        this.servicesOptions = this.mapValues(data, false, 'label')
      })
    axios.get('/public/v2/values/pricing_models')
      .then(({ data }) => {
        // Creating temporary variable where to hold data
        let temp = this.mapValues(data, false, 'label')
        // finding "Other" option obj. in array
        const findOtherOption = temp.find(skill => skill.text === 'Other')
        // Getting "Other" option obj. index in array
        const otherOptionIndex = temp.findIndex(skill => skill.text === 'Other')
        // Erasing "Other" obj from array
        temp.splice(otherOptionIndex, 1)
        // Sorting array by alphabetical order
        temp = temp.sort((a, b) => a.label.localeCompare(b.label))
        // Adding "Other" option at the end of array
        temp.push(findOtherOption)
        // renaming/overwriting temp variable
        this.pricingModelsOptions = temp
      })
    axios.get('/public/v2/values/scopes')
      .then(({ data }) => {
        // Creating temporary variable where to hold data
        let temp = this.mapValues(data, false, 'label')
        // finding "Other" option obj. in array
        const findOtherOption = temp.find(skill => skill.text === 'Other')
        // Getting "Other" option obj. index in array
        const otherOptionIndex = temp.findIndex(skill => skill.text === 'Other')
        // Erasing "Other" obj from array
        temp.splice(otherOptionIndex, 1)
        // Sorting array by alphabetical order
        temp = temp.sort((a, b) => a.label.localeCompare(b.label))
        // Adding "Other" option at the end of array
        temp.push(findOtherOption)
        // renaming/overwriting temp variable
        this.scopesOptions = temp
      })
    axios.get('/public/v2/values/working_types')
      .then(({ data }) => {
        let temp = this.mapValues(data, false, 'label')
        temp = temp.sort((a, b) => a.label.localeCompare(b.label))
        this.workingTypeOptions = temp
      })
    axios.get('/public/v2/values/fee_ranges')
      .then(({ data }) => {
        let temp = this.mapValues(data, false, 'label')
        temp = temp.sort((a, b) => a.label.localeCompare(b.label))
        this.feeRangeOptions = temp
      })
    axios.get('/public/v2/values/recruiter_types')
      .then(({ data }) => {
        let temp = this.mapValues(data, false, 'label')
        temp = temp.sort((a, b) => a.label.localeCompare(b.label))
        this.recruiterTypeOptions = temp
      })
    if (this.$route.params.id) {
      axios.get(`${this.isAdmin ? '/admin' : ''}/v2/projects/${this.$route.params.id}`)
        .then(({ data }) => {
          data.primary_contacts = (data.primary_contacts || []).map(r => r.id)
          data.secondary_contacts = (data.secondary_contacts || []).map(r => r.id)
          data.roles = (data.roles || []).map(r => r.id)
          data.regions = (data.regions || []).map(r => r.id)
          data.working_types = (data.working_types || []).map(r => r.id)
          data.scopes = (data.scopes || []).map(r => r.id)
          data.services = (data.services || []).map(r => r.id)
          data.fee_ranges = (data.fee_ranges || []).map(r => r.id)
          data.pricing_models = (data.pricing_models || []).map(r => r.id)
          data.recruiter_types = (data.recruiter_types || []).map(r => r.id)
          data.business_id = [data.business_id]
          this.form = data
        })
    }
  },
  methods: {
    mapValues (data, prepend = false, textField = 'name') {
      let dataArr = data.map(option => {
        option.value = option.id
        option.text = option[textField]
        return option
      })
      if (prepend) {
        dataArr = [
          { text: 'Select One', value: null },
          ...dataArr,
        ]
      }
      return dataArr
    },
    onSubmit (event) {
      // Clone form data to do some modifications
      const sendData = {
        ...this.form,
      }
      // Take first "business"
      sendData.business_id = sendData.business_id[0]
      // admin/manager edit & creat project logic
      if (this.isAdmin) {
        if (this.$route.params.id) {
          axios.put(`/admin/v2/projects/${this.$route.params.id}`, sendData)
            .then(() => this.$bvModal.show('modal-1'))
        } else {
          axios.post('/admin/v2/projects', sendData)
            .then(({ data }) => {
              this.$bvModal.show('modal-1')
            })
        }
      // Business edit project logic
      } else {
        axios.put(`/v2/projects/${this.$route.params.id}`, sendData)
          .then(() => {
            this.$router.push(`/project/${this.$route.params.id}`)
          })
      }
    },
    addMoreProjects () {
      location.reload()
    },
  },
}
</script>

<style lang="scss" scoped>
.main-container {
  .main-row {
    width: 550px;
  }
}
</style>
